<template>
  <div>
    <v-form ref="userProfileForm" v-model="valid" lazy-validation>
      <v-text-field
        v-model="name"
        :rules="[(v) => !!v || 'Name is required']"
        outlined
        label="Name"
        required
      ></v-text-field>

      <v-text-field
        v-model="description"
        outlined
        label="Description"
        required
      ></v-text-field>

      <v-text-field
        v-model="points"
        outlined
        label="Points"
        required
        type="number"
        :rules="pointsRules"
      ></v-text-field>

      <v-text-field
        v-model="surveyUrl"
        outlined
        label="Survey Url"
        :rules="[(v) => !!v || 'Survey Url is required']"
        required
      ></v-text-field>
      <v-select
        v-model="survey.loi"
        :items="loiItems"
        item-text="text"
        item-value="value"
        :rules="[(v) => !!v || 'Length Of Interview is required']"
        label="Length Of Interview"
        outlined
        required
      ></v-select>
      <v-select
        v-model="activityType"
        :items="activityTypeItems"
        :rules="[(v) => !!v || 'activty Type is required']"
        label="activty Type"
        outlined
        required
      ></v-select>
    </v-form>
    <v-btn class="mr-3" text @click="$emit('back')">
      back
    </v-btn>
    <v-btn color="primary" @click="createUserProfile">
      Create User Profile
    </v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      name: "",
      pointsRules: [
        (v) => !!v || "Points is required",
        (v) => (v && v > 0) || "Points must be greater than 0",
        (v) => (v && v <= 100) || "Points must be less than or equal to 100",
      ],
      points: 25,
      description: "",
      activityType: "fun",
      activityTypeItems: ["fun", "insight", "more", "other"],
      surveyUrl:"",
      loi: 5,
      loiItems: [
        {
          text: "1 minute",
          value: 1
        },
        {
          text: "2 minutes",
          value: 2
        },
        {
          text: "3 minutes",
          value: 3
        },
        {
          text: "5 minutes",
          value: 5
        },
        {
          text: "10 minutes",
          value: 10
        },
        {
          text: "12 minutes",
          value: 12
        },
        {
          text: "15 minutes",
          value: 15
        },
        {
          text: "20 minutes",
          value: 20
        },
        {
          text: "30 minutes",
          value: 30
        }
      ],
    };
  },
  methods: {
    createUserProfile() {
      let isValid = this.$refs.userProfileForm.validate();
      if (isValid) {
        this.$emit("createUserProfile", {
          name: this.name,
          description: this.description,
          points: this.points,
          activityType: this.activityType,
          surveyUrl: this.surveyUrl,
        });
      }
    },
  },
};
</script>
